export const state = {
  isFirstVisit: false,
  isLoggedIn: false,
  hasLoadedApp: false,
  jwt: null,
  isAuthenticating: true,
  authToken: null,
  errors: [],
  currentUser: null,
  currentPage: null,
  title: 'Chewbox',
  token: null,
  isLoading: false,
  isFullEditor: false,
  selectedImage: null,
};
