import gql from 'graphql-tag';
import { calendarFragment } from './fragments';

export const calendars = gql`
  query calendars($where: CalendarWhereInput, $orderBy: CalendarOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    calendars(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${calendarFragment}
  }
`;

export const getCalendar = gql`
  query calendar($calendarId: String!){
    calendar(calendarId: $calendarId) ${calendarFragment}
  }`;

export const getUserCalendars = gql`
  query getUserCalendars($userId: String! $skip: Int $after: String $before: String $first: Int $last: Int){
    getUserCalendars(userId:$userId skip:$skip after:$after before:$before first:$first last:$last) ${calendarFragment}
}`;