import { _ } from 'vue-underscore'
/*
*
*/
export const getTotalProducts = async ({ state, effects }, data) => {
  let options = {}
  if (data && data.options) options = data.options
  const { products } = await effects.gql.queries.products(options)

  state.product.totalRecords = products ? products.length : 0
}

/*
*
*/
export const getProducts = async ({ state, effects }, data) => {
  console.log('action getProducts...')
  try {
    let options = {}
    if (_.isEmpty(data)) {
      options = {
        first: state.product.productPerPage,
        skip: (state.product.activePage - 1) * state.product.productPerPage
      }
    } else {
      if (data && data.all) options = {first: 1}
      else {
        options = data;
        if (!data.first) options.first = state.product.productPerPage;
        if (!data.skip) options.skip = (state.product.activePage - 1) * state.product.productPerPage;
      }
    }
    //
    const { products } = await effects.gql.queries.products(options)
    console.log("######################", products);
    if (data && data.getValues) return products
    else state.product.products = products
  } catch (e) {
    console.log(e, 'getProducts errors');
  }
}

/*
*
*/
export const saveProduct = async ({ effects, actions }, data) => {
  try {
    return await effects.gql.mutations.saveProduct(data)
  } catch (e) {
    console.log(e, 'saveProduct errors')
    actions.alert.showError({ message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error', title: 'Saving Product' });
  }
}

/*
*
*/
export const onChangePage = async ({ state }, page) => {
  state.product.activePage = page
}

/*
*
*/
export const onProductAdded = ({ state }, data) => {
  state.product.push(data)
}

/*
*
*/
export const assignProductsToVendor = async ({ effects }, data) => {
  return await effects.gql.mutations.assignProductsToVendor(data)
}

/*
*
*/
export const mapProducts = async ({ effects }, data) => {
  return await effects.gql.mutations.mapProducts(data)
}

/*
*
*/
export const setProductsToActive = async ({ effects }, data) => {
  return await effects.gql.mutations.setProductsToActive(data)
}

/*
*
*/
export const addTaskToProduct = async ({ effects }, data) => {
  return await effects.gql.mutations.addTaskToProduct(data)
}

/*
*
*/
export const updateTaskOnProduct = async ({ effects }, data) => {
  return await effects.gql.mutations.updateTaskOnProduct(data)
}

/*
*
*/
export const removeTaskFromProduct = async ({ effects }, data) => {
  return await effects.gql.mutations.removeTaskFromProduct(data)
}

/*
*
*/
export const reorderTasks = async ({ effects }, data) => {
  return await effects.gql.mutations.reorderTasks(data)
}

/*
*
*/
export const setIngredientHoldTimes = async ({ effects }, data) => {
  return await effects.gql.mutations.setIngredientHoldTimes(data)
}

/*
*
*/
export const deleteProduct = async ({ effects }, data) => {
  return await effects.gql.mutations.deleteProduct(data)
}

/*
*
*/
export const setupProductionSchedule = async ({ effects }, data) => {
  return await effects.gql.mutations.setupProductionSchedule(data)
}

/*
*
*/
export const indexProducts = async ({ effects }, data) => {
  return await effects.gql.mutations.indexProducts(data)
}

/*
*
*/
export const importProductsFromCSV = async ({ effects }, data) => {
  return await effects.gql.mutations.importProductsFromCSV(data)
}

/*
*
*/
export const saveProductTask = async ({ effects }, data) => {
  return await effects.gql.mutations.saveProductTask(data)
}

/*
*
*/
export const deleteProductTask = async ({ effects }, data) => {
  return await effects.gql.mutations.deleteProductTask(data)
}

/*
*
*/
export const patchProducts = async ({ effects }, data) => {
  return await effects.gql.mutations.patchProducts(data)
}

/*
*
*/
export const findProductsWithNoPrice = async ({ effects }, data) => {
  return await effects.gql.queries.findProductsWithNoPrice(data)
}

/*
*
*/
export const product = async ({ effects }, data) => {
  return await effects.gql.queries.product(data)
}

/*
  *
  */
export const getTrendingProducts = async ({ state, effects }, data) => {
  console.log('action getTrendingProducts...')
  try {
    const { trendingProducts } = await effects.gql.queries.trendingProducts(data)
    if (data && data.getValues) return trendingProducts
    else state.product.trendingProducts = trendingProducts
  } catch (e) {
    console.log(e, 'getProducts errors');
  }
}

export const getRelatedProducts = async ({ state, effects }, data) => {
  console.log('action getRelatedProducts...')
  try {
    const { relatedProducts } = await effects.gql.queries.relatedProducts(data)
    if (data && data.getValues) return relatedProducts
    else state.product.relatedProducts = relatedProducts
  } catch (e) {
    console.log(e, 'getProducts errors');
  }
}

