<template>
  <div class="w-full h-screen fixed bg-select_gray sm:bg-white" style="width: 100%"></div>
</template>

<script>
import {reactive, toRefs} from "vue";

export default {
  name: "DefaultNavigation",
  setup() {
    const data = reactive({
    });
    return {
      ...toRefs(data),
    }
  },
}
</script>

<style scoped>
.content {
  padding: 0 15px;
  min-height: 84vh;
}
</style>
