<template>
  <button
    :type="type"
    :class="`inline-flex justify-center items-center btn-${variant||'primary'} btn-${size || 'md'} ${rounded} ${customClass} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`"
    @clink="click"
  >
    <slot v-if="leading" />
	  {{ content }}
    <svg v-if="isLoading" class="w-5 h-5 animate-spin ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
      </path>
    </svg>
  </button>
</template>

<script>
import { computed } from "vue";
/**
- Use a button for actions within applications
- different variants i.e. primary, secondary etc. and sizes supported
**/
export default {
  name: "Button",
  props: {
    /**
    used to provide type like button or submit. By default button is set.
    */
    type: {
      type: String,
      default: "button",
    },
    /**
    Button comes in different sizes like  sm, md, lg and xl. By default md is set.
    */
    size: {
      type: String,
      default: "",
    },
    /**
    Used this prop to assign custom classes to button
    */
    customClass: {
      type: String,
      default: "",
    },
    /**
    Text to be shown within button
    */
    content: {
      type: String,
      default: "",
    },
    /**
    switch between different variants i.e. primary, secondary, success, danger and themed
    */
    variant: {
      type: String,
      default: "primary",
    },
    /**
    Used for rounding corners of buttons. if set rounded-full is applied otherwise rounded is applied
    */
    isRounded: {
      type: Boolean,
      default: false,
    },

    click: {
      type: String,
      default: '',
    },

    leading: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    return {
      rounded: computed(() => {
        return `${props.isRounded ? "rounded-full" : "rounded"}`;
      }),
    };
  },
};
</script>

<style>
  .btn-primary {
    @apply bg-indigo-600 text-white hover:bg-indigo-500
  }
  .btn-secondary {
    @apply border border-gray-300 rounded-md text-gray-700 bg-white hover:text-gray-500 
  }
  .btn-success {
   @apply bg-green-500 hover:bg-green-700 text-white
  }
  .btn-danger {
    @apply bg-red-500 hover:bg-red-700 text-white
  }
  .btn-themed {
    @apply bg-theme hover:opacity-80 text-white
  }
  .btn-sm {
    @apply px-2 py-0 text-xs font-medium
  }
  .btn-md {
    @apply px-4 py-2 text-sm font-medium
  }
  .btn-lg {
    @apply px-6 py-2 text-base font-medium
  }
  .btn-xl {
    @apply px-8 py-3 text-base font-medium
  }
</style>
