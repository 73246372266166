export const state = {
  calendars: {},
  isLoading: false,
  currentCalendarId: null,
  calendarPerPage: 100,
  totalRecords: 10,
  activePage: 1,
  calendarList: calendarNamespace =>
    Object.values(calendarNamespace.calendars)
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1
        } else if (a.createdAt < b.createdAt) {
          return -1
        }
        return 0
      })
      .slice(0, calendarNamespace.calendarPerPage)
}
