import { createOvermind } from "overmind";
import { merge, namespaced } from "overmind/config";
import { createPlugin } from "overmind-vue";
import { onInitialize } from "./onInitialize";
import { state } from "./state";
import * as effects from "./effects";
import * as actions from "./actions";

// custom
import * as user from "./namespaces/user";
import * as inventory from './namespaces/inventory'
import * as alert from './namespaces/alert'
import * as product from './namespaces/product'
import * as event from './namespaces/event'
import * as calendar from './namespaces/calendar'
import * as cart from './namespaces/cart'
import * as order from './namespaces/order'
import * as contract from './namespaces/contract'
import * as tag from './namespaces/tag'
import * as group from './namespaces/group'

export const config = merge(
  {
    state,
    effects,
    actions,
    onInitialize,
  },
  namespaced({
    user,
    inventory,
    alert,
    product,
    event,
    calendar,
    cart,
    order,
    contract,
    tag,
    group
  })
);

const overmind = createOvermind(config, {
  devtools: false, // defaults to localhost:3031
});

export const OvermindPlugin = createPlugin(overmind);
