import gql from 'graphql-tag';
import { eventFragment } from './fragments';

/*
*
*/
export const saveEvent = gql`
  mutation saveEvent($data: EventUpdateInput!, $where: EventWhereUniqueInput) {
    saveEvent(data: $data, where: $where) ${eventFragment}
  }
`;

/*
*
*/
export const deleteEvent = gql`
  mutation deleteEvent($where: EventWhereUniqueInput) {
    deleteEvent(where: $where) ${eventFragment}
  }
`;

/*
*
*/
export const saveEventVendorApplication = gql`
  mutation saveEventVendorApplication($data: EventVendorApplicationUpdateInput! $where: EventVendorApplicationWhereUniqueInput){
    saveEventVendorApplication(data: $data where: $where){
      id 
      user{id fullName}
      company{id username name}
      products {id name posItemId site{id address name}}
      transactions{id paymentMethodToken amountPaid}
      event${eventFragment}
      eventDays{id name}
      type
      metadata
      status
      deadline
      updatedAt
      createdAt
    }
  }
`
