import gql from 'graphql-tag';
import { userFragment } from '../user/fragments'

export const cartFragment = gql`{
  id subtotal tipPercentage tip tax total discount delivery shipping isPending createdAt updatedAt
  serviceFee wallet rebates dueNow
  event { id name days { id name startDate } }
  securityDeposit
  deposit
  balance
  retailers metadata
  contacts${userFragment}
  items { id name description priceEach quantity deliverBy pickUpBy rating isReviewed isPromo promoCost
    pickUpFrom{id name}
    modifiers { id name sortOrder
        ingredient { id name }
    }
    rebateAmount discountAmount
    campaign { id discountType discountAmount ads{id images{id desktop}} maxItemsPer }
    deliverTo { id name address address2 state city postalCode country phones{id number} }
    product { id name description isAddOn
      pricing { id type retailPrice }
      blurb
      images{id url}
      tasks { id
          ingredient { id name }
      }
    }
  }  
}`

