import gql from 'graphql-tag';
import { cartFragment } from '../cart/fragments'

export const orderFragment = gql`{
  id
	cart ${cartFragment}
  user {
		id
		balance
		rebateBalance
		appVersion
		needToUpgrade
		timezoneOffset
		timezone
		playerId
		chatId
		username
		email
		bio
		fullName
		fullNameLower
		firstName
		firstNameLower
		middleName
		middleNameLower
		lastName
		lastNameLower
		title
		dateOfBirth
		gender
		status
		avatar
		unreadMessages
		braintreeCustomerId
		stripeConnectedAccountId
		isActive
		createdAt
		updatedAt
	}
	driver {
		id
		balance
		rebateBalance
		appVersion
		needToUpgrade
		timezoneOffset
		timezone
		playerId
		chatId
		username
		email
		bio
		fullName
		fullNameLower
		firstName
		firstNameLower
		middleName
		middleNameLower
		lastName
		lastNameLower
		title
		dateOfBirth
		gender
		status
		avatar
		unreadMessages
		braintreeCustomerId
		stripeConnectedAccountId
		isActive
		createdAt
		updatedAt
	}
	pickUpFrom {
		id
		name
		nameLower
		description
		slug
		rating
		address
		address2
		city
		state
		postalCode
		county
		country
		likeCount
		typeName
		geoFenceRadius
		isActive
		servesAlcohol
		isCommercial
		isFranchise
		taxRate
		avatar
		visits
		uniqueVisits
		inDevelopment
		googlePlacesId
		sortOrder
		launchDate
		createdAt
		updatedAt
	}
	deliverTo {
		id
		name
		nameLower
		description
		slug
		rating
		address
		address2
		city
		state
		postalCode
		county
		country
		likeCount
		typeName
		geoFenceRadius
		isActive
		servesAlcohol
		isCommercial
		isFranchise
		taxRate
		avatar
		visits
		uniqueVisits
		inDevelopment
		googlePlacesId
		sortOrder
		launchDate
		createdAt
		updatedAt
	}
	items {
		id
		name
		description
		product {
			id
			name
			nameLower
			description
			slug
			symbol
			posItemId
			likeCount
			rating
			sortOrder
			showOnHHMenu
			isHidden
			isActive
			isAlcohol
			isFood
			isAddOn
			isTaxable
			isRefundable
			isEticket
			onSale
			isFree
			createdAt
			updatedAt
			images {
				id
				name
				url
				width
				height
				source
				photoReference
			}
		}
		user {
			id
			balance
			rebateBalance
			appVersion
			needToUpgrade
			timezoneOffset
			timezone
			playerId
			chatId
			username
			email
			bio
			fullName
			fullNameLower
			firstName
			firstNameLower
			middleName
			middleNameLower
			lastName
			lastNameLower
			title
			dateOfBirth
			gender
			status
			avatar
			unreadMessages
			braintreeCustomerId
			stripeConnectedAccountId
			isActive
			createdAt
			updatedAt
		}
		cart {
			id
			subtotal
			tipPercentage
			tip
			tax
			total
			dueNow
			discount
			delivery
			shipping
			serviceFee
			wallet
			rebates
			isPending
			createdAt
			updatedAt
		}
		deliverBy
		quantity
		discountType
		discountAmount
		priceEach
		subtotal
		tax
		total
		discount
		delivery
		shipping
		isReviewed
		rating
		sortOrder
		createdAt
		updatedAt
	}
	subtotal
	tipPercentage
	tip
	tax
	total
	dueNow
	discount
	delivery
	shipping
	serviceFee
	wallet
	rebates
	sortOrder
	transactions {
		id
		paymentMethodToken
		amountPaid
		transactionId
		walletAmount
		note
		createdAt
	}
	useWallet
	isEvent
	isCancelledByCustomer
	isCancelledByOperator
	isArchived
	isCompleted
	isPaid
	isDelivered
	isDelivery
	isReturned
	isReadyForDelivery
	isOnTheWay
	isBeingPrepared
	isReviewed
	deliverBy
	assignedAt
	pickUpBy
	pickedUpAt
	preparedAt
	shipDate
	deliveredAt
	cancelledAt
	notes
	deliveryNotes
	giftedCount
	paymentMethod {
		id
		methodType
		vendor
		type
		cardType
		last4
		nameOnAccount
		routingNumberLast
		isValid
		isDefault
		image
		expirationDate
		createdAt
	}
	studentName
	createdAt
}`
