import gql from 'graphql-tag';
import { cartFragment } from './fragments';

/*
*
*/
export const saveCart = gql`
  mutation saveCart(
    $userId: String!
    $cartId: String
    $eventId: String
    $orderId: String
    $deleteCart: Boolean
    $addItems: [CartItemInput!]
    $updateItems: [CartItemInput!]
    $removeItems: [CartItemInput!]
    $tipAmount: Float
    $metadata: Json
    $findMarketplace: Boolean
    $tipType: String) {
      
    saveCart(userId:$userId cartId:$cartId eventId:$eventId orderId: $orderId deleteCart:$deleteCart addItems:$addItems updateItems:$updateItems
      removeItems:$removeItems tipAmount:$tipAmount tipType:$tipType metadata: $metadata findMarketplace: $findMarketplace  
    ) ${cartFragment}
  }
`;

/*
*
*/
export const deleteCart = gql`
  mutation deleteCart($where: CartWhereUniqueInput) {
    deleteCart(where: $where) ${cartFragment}
  }
`;

/*
*
*/
export const recalculateCart = gql`
  mutation recalculateCart($cartId: String!, $orderId: String) {
    recalculateCart(cartId: $cartId, orderId: $orderId) ${cartFragment}
  }
`;

/*
*
*/
export const updateTip = gql`
  mutation updateTip($cartId: String!, $tipAmount: Float!, $tipType: String!) {
    updateTip(cartId: $cartId, tipAmount: $tipAmount, tipType: $tipType) ${cartFragment}
  }
`;

/*
*
*/
export const saveCartItem = gql`
  mutation saveCartItem($data: CartItemUpdateInput!, $where: CartItemWhereUniqueInput) {
    saveCartItem(data: $data, where: $where)
  }
`;

/*
*
*/
export const saveCartContacts = gql`
  mutation saveCartContacts($data: UserUpdateManyInput!, $where: CartItemWhereUniqueInput!) {
    saveCartContacts(data: $data, where: $where) ${cartFragment}
  }
`;

/*
*
*/
export const deleteCartItem = gql`
  mutation deleteCartItem($where: CartItemWhereUniqueInput) {
    deleteCartItem(where: $where)
  }
`;

/*
*
*/
export const testSaveCart = gql`
  mutation testSaveCart {
    testSaveCart
  }
`;
