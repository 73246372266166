import gql from 'graphql-tag';
import { orderFragment } from './fragments';

/*
*
*/
export const orders = gql`
  query orders($where: OrderWhereInput, $orderBy: OrderOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    orders(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${orderFragment}
  }
`;

/*
*
*/
export const getOrdersForDelivery = gql`
  query getOrdersForDelivery($date: DateTime) {
    getOrdersForDelivery(date: $date) 
  }
`;

/*
*
*/
export const getDriverOrdersForDelivery = gql`
  query getDriverOrdersForDelivery($driverId: String!) {
    getDriverOrdersForDelivery(driverId: $driverId) 
  }
`;

/*
*
*/
export const order = gql`
  query order($orderId: String!) {
    order(orderId: $orderId) ${orderFragment}
  }
`;

/*
*
*/
export const getOrdersByTask = gql`
  query getOrdersByTask($date: DateTime, $where: OrderWhereInput, $orderBy: OrderOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    getOrdersByTask(date: $date, where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${orderFragment}
  }
`;

/*
*
*/
export const getOrdersByFood = gql`
  query getOrdersByFood($date: DateTime, $where: OrderWhereInput, $orderBy: OrderOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    getOrdersByFood(date: $date, where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${orderFragment}
  }
`;

/*
*
*/
export const getOrdersBySchool = gql`
  query getOrdersBySchool($date: DateTime, $where: OrderWhereInput, $orderBy: OrderOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    getOrdersBySchool(date: $date, where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${orderFragment}
  }
`;

/*
*
*/
export const getOrdersByCustomer = gql`
  query getOrdersByCustomer($date: DateTime, $where: OrderWhereInput, $orderBy: OrderOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    getOrdersByCustomer(date: $date, where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${orderFragment}
  }
`;

/*
*
*/
export const getInviteeOrders = gql`
  query getInviteeOrders($eventId: String!, $userId: String!) {
    getInviteeOrders(eventId: $eventId, userId: $userId) ${orderFragment}
  }
`;

/*
*
*/
export const getUsersWithAnOrder = gql`
  query getUsersWithAnOrder {
    getUsersWithAnOrder 
  }
`;

/*
*
*/
export const notifyUsersWithOrdersForDeliveryToday = gql`
  query notifyUsersWithOrdersForDeliveryToday {
    notifyUsersWithOrdersForDeliveryToday 
  }
`;

/*
*
*/
export const notifyUsersToOrderBySix = gql`
  query notifyUsersToOrderBySix {
    notifyUsersToOrderBySix 
  }
`;
