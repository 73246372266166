export const state = {
  contracts: {},
  isLoading: false,
  currentContractId: null,
  contractPerPage: 10,
  totalRecords: 10,
  activePage: 1,
  addOns: [],
  currentContract: {},
  isOpenNew: false,
  contractList: contractNamespace =>
    Object.values(contractNamespace.contracts)
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return 1
        } else if (a.createdAt < b.createdAt) {
          return -1
        }

        return 0
      })
      .slice(0, contractNamespace.contractPerPage)
}
