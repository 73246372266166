import { _ } from 'vue-underscore'
  /*
  *
  */
  export const getGroups = async ({ state, effects }, data) => {
    console.log('action getGroups...')
    try {
      let options = {}
      if(_.isEmpty(data)) {
        options = {
          first: state.group.groupPerPage,
          skip: (state.group.activePage - 1) * state.group.groupPerPage
        }
      } else {
        if (data && data.all) options = {}
        else {
          options = data;
          if(!data.first) options.first = state.group.groupPerPage;
          if(!data.skip) options.skip = (state.group.activePage - 1) * state.group.groupPerPage;
        }
      }
      //
      const { groups } = await effects.gql.queries.groups(options)
      if (data && data.getValues) return groups
      else state.group.groups = groups
    } catch (e) {
      console.log(e, 'getGroups errors');
    }
  }

