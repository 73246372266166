import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Home"),
    meta: {
      isFullPage: true,
      isDark: true,
    },
  },
  {
    path: "/booking_result",
    name: "Booking Result",
    component: () => import("@/pages/BookingResult"),
  },
  {
    path: "/booking_preview",
    name: "Booking Preview",
    component: () => import("@/pages/BookingPreview"),
  },
  {
    path: "/booked_result",
    name: "Booked Result",
    component: () => import("@/pages/BookedResult"),
  },
  {
    path: "/booking_payment",
    name: "Booking Payment",
    component: () => import("@/pages/BookingPayment"),
  },
  {
    path: "/booking_success",
    name: "Booking Success",
    component: () => import("@/pages/BookingSuccess"),
  },
  {
    path: "/scheduling",
    name: "Scheduling",
    component: () => import("@/pages/Scheduling"),
  },
  {
    path: "/signature",
    name: "Signature",
    component: () => import("@/pages/Signature"),
  },
  {
    path: "/schedule-user",
    name: "Schedule User",
    component: () => import("@/pages/ScheduleUser"),
  },
  {
    path: "/404",
    name: "Page Not Found",
    component: () => import("@/pages/PageNotFound"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/pages/About"),
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: () => import("@/pages/Blogs"),
  },
  {
    path: "/blogs/:id",
    name: "Blog Details",
    component: () => import("@/pages/BlogDetails"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/pages/Contact"),
  },
  {
    path: "/contact-success",
    name: "Contact Success",
    component: () => import("@/pages/ContactSuccess"),
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("@/pages/Dinning"),
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("@/pages/Events"),
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("@/pages/Payment"),
  },
  {
    path: "/profile",
    name: "My Account",
    component: () => import("@/pages/Profile"),
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("@/pages/Gallery"),
  },
  {
    path: "/packages",
    name: "Packages",
    component: () => import("@/pages/Packages"),
  },
  {
    path: "/packages/:id",
    name: "packages Details",
    component: () => import("@/pages/PackageDetails"),
  },
  {
    path: "/upcoming-events",
    name: "Upcoming",
    component: () => import("@/pages/Upcoming"),
  },
  {
    path: "/photos",
    name: "Photos",
    component: () => import("@/pages/Photos"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
})

// router.beforeEach(async (to, from, next) => {
//   const IsItABackButton = window.popStateDetected
//   window.popStateDetected = false
//   const derivedState = (from && from.matched && from.matched[0] && from.matched[0]['instances'] && from.matched[0]['instances']['default']) ? from.matched[0]['instances']['default']['state'] : state;
//   const homeRoute = 'Home1';
//   const loginRoute = 'Login';
//
//   console.log(to, window.location.href)
//   const url = window.location.href;
//   if (url?.includes('?code=') && url?.includes('&scopes=')) {
//     console.log('---------------');
//     const data = url?.split('?code=')[1];
//     const code = data?.split('&scopes=')[0];
//     console.log(code);
//     // next({name: 'Youtube Upload', query: {code: code}})
//     window.location.href = Settings.mainDomainFullPath + '#/tiktok/uploaded?code=' + code
//     return false;
//   }
//   if (to.matched.some(routeRecord => routeRecord.meta.requiresAuth)) {
//     if (!derivedState.currentUser?.id) {
//       if (to.name === 'StorylineDetails' && to.query?.id && to.query?.sId) {
//         next({ name: 'Share Scene', query: { ...to.query } })
//         return false;
//       } else {
//         const { token, userId } = await actions.getStoredAuthToken();
//         await actions.loginWithToken({ state, effects }, { token, userId });
//       }
//       // await actions.setStoredAuthToken();
//     }
//     if (!derivedState.currentUser?.id) {
//       if (to.name === 'StorylineDetails') {
//         localStorage.setItem('share_storyline_link', to.fullPath)
//       }
//       next({ name: loginRoute })
//     }
//     else next()
//   } else {
//     if (to.name === 'ConfirmEmail' || to.name === 'ShortUrl' || to.name === 'Video Player' || to.name === 'Company Profile' || to.name === 'Default Term' || to.name === 'Default Privacy' || to.name === 'Tiktok Auth' || to.name === 'Youtube Upload' || to.name === 'Facebook Auth') {
//       next()
//     } else {
//       if (!derivedState.isLoggedIn) {
//         const { token, userId } = await actions.getStoredAuthToken();
//         await actions.loginWithToken({ state, effects }, { token, userId });
//       }
//       if (to.name === 'HomeScreens') {
//         if (derivedState.isLoggedIn && derivedState.currentUser?.company?.id) next({ name: homeRoute });
//         else next({ name: loginRoute });
//       } else {
//         if (derivedState.isLoggedIn && derivedState.currentUser?.company?.id) {
//           const derivedActions = (from && from.matched && from.matched[0] && from.matched[0]['instances'] && from.matched[0]['instances']['default']) && from.matched[0]['instances']['default']['actions']
//           if (derivedActions && IsItABackButton) {
//             window.history.go(-1)
//             derivedActions.alert.showError({ message: 'You\'re already logged in. You should logout first!' })
//           } else {
//             next({ name: homeRoute });
//           }
//         }
//         else next();
//       }
//     }
//
//   }
// });

export default router
