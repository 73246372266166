import gql from 'graphql-tag';
import { productFragment } from './fragments';

/*
*
*/
export const saveProduct = gql`
  mutation saveProduct($data: ProductUpdateInput!, $where: ProductWhereUniqueInput) {
    saveProduct(data: $data, where: $where) ${productFragment}
  }
`;

/*
*
*/
export const deleteProduct = gql`
  mutation deleteProduct($where: ProductWhereUniqueInput) {
    deleteProduct(where: $where) ${productFragment}
  }
`;

/*
*
*/
export const assignProductsToVendor = gql`
  mutation assignProductsToVendor {
    assignProductsToVendor 
  }
`;

/*
*
*/
export const mapProducts = gql`
  mutation mapProducts($productId: String, $siteId: String, $products: Json, $buildES: Boolean) {
    mapProducts(productId: $productId, siteId: $siteId, products: $products, buildES: $buildES) 
  }
`;

/*
*
*/
export const setProductsToActive = gql`
  mutation setProductsToActive {
    setProductsToActive ${productFragment}
  }
`;

/*
*
*/
export const addTaskToProduct = gql`
  mutation addTaskToProduct {
    addTaskToProduct ${productFragment}
  }
`;

/*
*
*/
export const updateTaskOnProduct = gql`
  mutation updateTaskOnProduct {
    updateTaskOnProduct ${productFragment}
  }
`;

/*
*
*/
export const removeTaskFromProduct = gql`
  mutation removeTaskFromProduct {
    removeTaskFromProduct ${productFragment}
  }
`;

/*
*
*/
export const reorderTasks = gql`
  mutation reorderTasks {
    reorderTasks ${productFragment}
  }
`;

/*
*
*/
export const setIngredientHoldTimes = gql`
  mutation setIngredientHoldTimes {
    setIngredientHoldTimes ${productFragment}
  }
`;

/*
*
*/
export const setupProductionSchedule = gql`
  mutation setupProductionSchedule($targetDate: DateTime) {
    setupProductionSchedule(targetDate: $targetDate) 
  }
`;

/*
*
*/
export const indexProducts = gql`
  mutation indexProducts($where: ProductWhereInput!, $clearIndexFirst: Boolean) {
    indexProducts(where: $where, clearIndexFirst: $clearIndexFirst) 
  }
`;

/*
*
*/
export const importProductsFromCSV = gql`
  mutation importProductsFromCSV {
    importProductsFromCSV 
  }
`;

/*
*
*/
export const saveProductTask = gql`
  mutation saveProductTask($data: ProductTaskUpdateInput!, $where: ProductTaskWhereUniqueInput) {
    saveProductTask(data: $data, where: $where) 
  }
`;

/*
*
*/
export const deleteProductTask = gql`
  mutation deleteProductTask($where: ProductTaskWhereUniqueInput) {
    deleteProductTask(where: $where) 
  }
`;

/*
*
*/
export const patchProducts = gql`
  mutation patchProducts {
    patchProducts 
  }
`;
