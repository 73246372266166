import gql from 'graphql-tag';

export const groupFragment = gql`{
    id
    name
    nameLower
    type
    description
    groups {
        id
        name
        nameLower
        type
        description
    }
}`
