import { keyBy, isEmpty } from "lodash";
import { json } from "overmind";

export const checkInventory = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.checkInventory(data);
  } catch (e) {
    console.log(e, "checkInventory error look");
  }
};
