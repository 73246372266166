import gql from 'graphql-tag';
import { calendarFragment } from './fragments';

export const createCalendarNotifyAttendees = gql`
  mutation createCalendarNotifyAttendees( $data: CalendarCreateInput! $users: [String!]! $googlePlacesId: String $gps:GeoLocCreateInput) {
    createCalendarNotifyAttendees(data:$data users:$users googlePlacesId:$googlePlacesId gps:$gps) ${calendarFragment}
  }
`;

export const saveCalendar = gql`
  mutation saveCalendar($data: CalendarUpdateInput!, $where: CalendarWhereUniqueInput) {
    saveCalendar(data: $data, where: $where) ${calendarFragment}
  }
`;

export const deleteCalendar = gql`
  mutation deleteCalendar($where: CalendarWhereUniqueInput) {
    deleteCalendar(where: $where) ${calendarFragment}
  }
`;
