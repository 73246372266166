import { graphql } from "overmind-graphql";

import * as userMutations from "./user/mutations";
import * as userQueries from "./user/queries";

import * as groupQueries from './group/queries'

import * as productMutations from './product/mutations'
import * as productQueries from './product/queries'
import * as productSubscriptions from './product/subscriptions'

import * as inventoryMutations from './inventory/mutations'
import * as inventoryQueries from './inventory/queries'

import * as eventMutations from './event/mutations'
import * as eventQueries from './event/queries'

import * as calendarMutations from './calendar/mutations'
import * as calendarQueries from './calendar/queries'

import * as cartMutations from './cart/mutations'
import * as cartQueries from './cart/queries'

import * as orderMutations from './order/mutations'
import * as orderQueries from './order/queries'

import * as tagMutations from './tag/mutations'
import * as tagQueries from './tag/queries'

import * as contractMutations from './contract/mutations'
import * as contractQueries from './contract/queries'

export default graphql({
  subscriptions: {
    ...productSubscriptions
  },
  queries: {
    ...userQueries,
    ...inventoryQueries,
    ...productQueries,
    ...eventQueries,
    ...calendarQueries,
    ...orderQueries,
    ...cartQueries,
    ...tagQueries,
    ...contractQueries,
    ...groupQueries
  },
  mutations: {
    ...userMutations,
    ...inventoryMutations,
    ...productMutations,
    ...eventMutations,
    ...calendarMutations,
    ...orderMutations,
    ...cartMutations,
    ...tagMutations,
    ...contractMutations
  },
});
