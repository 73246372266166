import gql from "graphql-tag";
import { userFragment, AuthPayLoad } from "./fragments";

/*
 *
 */
export const saveUser = gql`
  mutation saveUser($data: UserUpdateInput!, $where: UserWhereUniqueInput) {
    saveUser(data: $data, where: $where) ${userFragment}
  }
`;

/*
 *
 */
export const deleteUser = gql`
  mutation deleteUser($where: UserWhereUniqueInput) {
    deleteUser(where: $where) ${userFragment}
  }
`;

/*
 *
 */
export const createAppUser = gql`
  mutation createAppUser($mobileNumber: String $email: String $password: String $generateToken: Boolean $disableSendingSmsCode: Boolean, $appVersion: String $sendEmailVerification: Boolean $userType: String) {
    createAppUser(generateToken: $generateToken email: $email password: $password mobileNumber: $mobileNumber disableSendingSmsCode: $disableSendingSmsCode appVersion: $appVersion sendEmailVerification: $sendEmailVerification userType: $userType) ${AuthPayLoad}
  }
`;

/*
 *
 */
export const verifySmsCode = gql`
  mutation verifySmsCode(
    $userId: String!
    $verifyCode: String!
    $mobileNumber: String!
    $device: Json
  ) {
    verifySmsCode(
      userId: $userId
      verifyCode: $verifyCode
      mobileNumber: $mobileNumber
      device: $device
    ) {
      user {
        id
      }
      token
    }
  }
`;

/*
 *
 */
export const login = gql`
  mutation login($email:String! $password:String!) {
    login(email:$email password:$password) {
      token
      user ${userFragment}
    }
  }
`;

/*
 *
 */
export const loginWithToken = gql`
  mutation loginWithToken($token:String! $userId:String! $appVersion:String) {
    loginWithToken(token:$token userId:$userId appVersion:$appVersion) {
      user ${userFragment}
    }
  }
`;

/*
 *
 */
export const triggerPasswordReset = gql`
  mutation triggerPasswordReset($email: String!, $domain: String!) {
    triggerPasswordReset(email: $email, domain: $domain)
  }
`;

/*
 *
 */
export const passwordReset = gql`
  mutation passwordReset(
    $email: String!
    $resetToken: String!
    $password: String!
  ) {
    passwordReset(email: $email, resetToken: $resetToken, password: $password)
  }
`;

/*
 *
 */
export const requestSupport = gql`
  mutation requestSupport(
    $userId: String
    $subject: String!
    $message: String!
    $email: String
    $phone: String
    $reason: String
    $companies: [String!]
  ) {
    requestSupport(
      userId: $userId
      subject: $subject
      message: $message
      email: $email
      phone: $phone
      reason: $reason
      companies: $companies
    )
  }
`;

export const updateUserProfile = gql`
  mutation updateUser(
    $userId: String! 
    $email: String 
    $avatar: String 
    $gender: String
    $fullName: String
    $firstName: String 
    $lastName: String 
    $paymentMethod: PaymentMethodInput 
    $removePaymentMethods: [String] 
    $removeEmails: [String] 
    $removePhones: [String]
    $lunchtime: DateTime 
    $googlePlacesId: String 
    $gps: GeoLocCreateInput 
    $timezoneOffset: Int 
    $timezone: String $address2: String
    $addressNumber: String
    $addressName: String
    $siteId: String
    ) {
    updateUser(userId: $userId, email: $email avatar:$avatar fullName:$fullName firstName:$firstName lastName:$lastName
      paymentMethod:$paymentMethod removePaymentMethods:$removePaymentMethods removeEmails:$removeEmails removePhones:$removePhones lunchtime:$lunchtime gender: $gender
      googlePlacesId:$googlePlacesId gps:$gps timezoneOffset:$timezoneOffset timezone:$timezone address2: $address2
      addressNumber: $addressNumber
      addressName: $addressName
      siteId: $siteId
    ) ${AuthPayLoad}
  }
`;

export const deactivateUser = gql`
  mutation deactivateUser($userId: String!) {
    deactivateUser(userId: $userId)
  }
`;

export const validateFluteCode = gql`
  mutation validateFluteCode(
    $promoCode: String!
    $userId: String!
    $image: String
    $locationPlaceId: String
  ) {
    validateFluteCode(
      promoCode: $promoCode
      userId: $userId
      image: $image
      locationPlaceId: $locationPlaceId
    )
  }
`;

export const sendFlutes = gql`
  mutation sendFlutes(
    $fromUserId: String!
    $toFlutes: [ToFlute!]
    $useWallet: Boolean
  ) {
    sendFlutes(
      toFlutes: $toFlutes
      fromUserId: $fromUserId
      useWallet: $useWallet
    )
  }
`;

export const doAccountsExist = gql`
  mutation doAccountsExist($mobileNumbers: [String]) {
    doAccountsExist(mobileNumbers: $mobileNumbers) {
      id
      avatar
      username
      phones {
        number
        verified
      }
      status
    }
  }
`;

export const inviteContact = gql`
  mutation inviteContact(
    $contacts: [InviteContactCreateInput!]
    $userId: String!
  ) {
    inviteContact(contacts: $contacts, userId: $userId)
  }
`;

export const deleteUserAddress = gql`
    mutation deleteUserAddress($siteId: String! $userId: String!){
        deleteUserAddress(siteId: $siteId, userId: $userId)${userFragment}
    }
`;

export const uploadFileToS3 = gql`
  mutation uploadFileToS3($image: String!, $path: String, $stream: Boolean) {
    uploadFileToS3(image: $image, path: $path, stream: $stream)
  }
`;

export const inviteUserToProject = gql`
  mutation inviteUserToProject(
    $fullName: String!
    $email: String!
    $mobile: String
    $role: String
    $platforms: [String]
    $note: String
    $projectId: String!
  ) {
    inviteUserToProject(
      fullName: $fullName
      email: $email
      mobile: $mobile
      role: $role
      platforms: $platforms
      note: $note
      projectId: $projectId
    )
  }
`;

export const saveContactRequest = gql`
  mutation saveContactRequest(
    $data: ContactRequestUpdateInput!
    $where: ContactRequestWhereUniqueInput
  ) {
    saveContactRequest(data: $data, where: $where) {
      id
    }
  }
`;

export const makeReservations = gql`
  mutation makeReservations(
    $eventId: String
    $siteId: String
    $seatIds: [String]
    $sectionId: String
    $layoutId: String
    $scheduleId: String!
    $userId: String!
    $reservationDate: DateTime!
  ) {
    makeReservations(
      eventId: $eventId
      siteId: $siteId
      seatIds: $seatIds
      sectionId: $sectionId
      layoutId: $layoutId
      scheduleId: $scheduleId
      userId: $userId
      reservationDate: $reservationDate
    )
  }
`;

export const saveReservation = gql`
  mutation saveReservation(
    $data: ReservationUpdateInput!
    $where: ReservationWhereUniqueInput
  ) {
    saveReservation(data: $data, where: $where) {
      id
    }
  }
`;

export const generateBraintreeClientToken = gql`
  mutation generateBraintreeClientToken($customerId: String!) {
    generateBraintreeClientToken(customerId: $customerId)
  }
`;

export const generatePayCode = gql`
  mutation generatePayCode(
    $name: String!
    $phone: String!
    $email: String!
    $type: String!
    $amount: Float!
    $products: Json
    $promoCode: String
    $userId: String
    $cartId: String
  ) {
    generatePayCode(
      name: $name
      phone: $phone
      email: $email
      type: $type
      amount: $amount
      products: $products
      promoCode: $promoCode
      userId: $userId
      cartId: $cartId
    )
  }
`;
