<template>
  <div class="w-screen" :class="{'fullpage': this.$route.meta.isFullPage, 'light-hero-colors': this.$route.meta.isDark}">
    <slot/>
  </div>
  <Alert :type="state.alert.type" :message="state.alert.message" :visible="state.alert.visible"
		:title="state.alert.title" />
</template>

<script>
// import Footer from "@/components/Footer";
import {reactive, toRefs} from "vue";
import Alert from "@/components/common/Alert";

export default {
  name: "Default",
  components: {
    Alert
  },
  setup() {
    const data = reactive({
    });
    return {
      ...toRefs(data),
    }
  }
}
</script>

<style scoped>
</style>
