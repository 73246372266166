import gql from 'graphql-tag';
import { eventFragment } from './fragments';

/*
*
*/
export const events = gql`
  query events($where: EventWhereInput, $orderBy: EventOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    events(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${eventFragment}
  }
`;

// export const products = gql`
//   query products($where: ProductWhereInput, $orderBy: ProductOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
//     products(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) {
//       id 
//       name
//       description 
//       blurb
//       slug
//       symbol
//       cloneOf {id}
//       likeCount
//       images {id name source}
//       pricing {id type weekday startTime stopTime retailPrice salePrice wholesalePrice fee quantityLimit unitSize}
//       eventDays{id name description startDate, endDate site{id name}}
//       posItemId
//       medias{id name source}
//     }
//   }
// `
