import gql from 'graphql-tag';
import { productFragment } from './fragments';

/*
*
*/
export const products = gql`
  query products($where: ProductWhereInput, $orderBy: ProductOrderByInput, $skip: Int, $after: String, $before: String, $first: Int, $last: Int) {
    products(where: $where, orderBy: $orderBy, skip: $skip, after: $after, before: $before, first: $first, last: $last) ${productFragment}
  }
`;

/*
*
*/
export const findProductsWithNoPrice = gql`
  query findProductsWithNoPrice($siteId: String) {
    findProductsWithNoPrice(siteId: $siteId) ${productFragment}
  }
`;

/*
*
*/
export const product = gql`
  query product($productId: String!) {
    product(productId: $productId) ${productFragment}
  }
`;

/*
*
*/
export const trendingProducts = gql`
  query trendingProducts($startDate: DateTime, $endDate: DateTime, $skip: Int, $first: Int) {
    trendingProducts(startDate: $startDate, endDate: $endDate, skip: $skip, first: $first) {
      id 
      name
      posItemId
      images {
        id
        name
        url
        width
        height
        source
      }
      site {
        id
        name
      }
      productGroup {
        id
        name
        nameLower
        description
      }
    }
  }
`;
