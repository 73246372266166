import gql from 'graphql-tag';
import { tagFragment } from './fragments';

/*
*
*/
export const saveTag = gql`
  mutation saveTag($data: TagUpdateInput!, $where: TagWhereUniqueInput) {
    saveTag(data: $data, where: $where) ${tagFragment}
  }
`;

/*
*
*/
export const deleteTag = gql`
  mutation deleteTag($where: TagWhereUniqueInput) {
    deleteTag(where: $where) ${tagFragment}
  }
`;

/*
*
*/
export const checkInventory = gql`
  mutation checkInventory($siteId: String, $googlePlaceId: String, $productId: String, $quantity: Int, $startDate: DateTime!, $endDate: DateTime!) {
    checkInventory(siteId: $siteId, googlePlaceId: $googlePlaceId, productId: $productId, quantity: $quantity, startDate: $startDate, endDate: $endDate)
  }
`

