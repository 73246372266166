import gql from 'graphql-tag';

export const PaymentMethod = `
{
  id type expirationDate cardType last4 isValid isDefault createdAt methodType isValid image
  vendors { name token callbackUrl redirectUrl isValid checkoutUrl }
}
`;
/*
*
*/
export const userFragment = gql`{
 id
  balance
  bio
  appVersion
  needToUpgrade
  timezoneOffset
  chatId
  playerId
  gender
  timezone
  firstName
  firstNameLower
  middleName
  company {id username name locations {id} productSubscriptions{id name isActive renewalType}}
  middleNameLower
  lastName
  lastNameLower
  fullName
  fullNameLower
  dateOfBirth
  phones{id number}
  email
  avatar
  braintreeCustomerId
  createdAt
  updatedAt
  site { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{lat lon} }
  sites { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{lat lon}}
  schedule{id name weekday slug clockIns{id clockIn clockOut}}
  paymentMethods${PaymentMethod}
  groups { id name }
  orders { id }
}`;

/*
*
*/
export const AuthPayLoad = gql`
{
  user${userFragment}
  verificationCode
}
`;

export const contactRequest = gql`{
  id name subject comment emails phones user {id} createdAt
}`


