import { createApp } from "vue";
import App from "./App.vue";
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";
import { OvermindPlugin } from "@/store";
import { setupCalendar } from 'v-calendar';

import router from "./router";
// CSS
import "./main.css";
//
import "./assets/css";

//
/** Globally register layout component */
import Default from "./layouts/Default";
import DefaultNavigation from "./layouts/DefaultNavigation";

createApp(App)
  .use(router)
  .use(Vue3VideoPlayer)
  .use(OvermindPlugin)
  .use(setupCalendar, {})
  .component("default-layout", Default)
  .component("default-navigation-layout", DefaultNavigation)
  .mount("#app");
