import { _ } from "vue-underscore";
import moment from "moment";
import { json } from "overmind";
import { groupBy } from "lodash";
/*
 *
 */
export const getTotalCarts = async ({ state, effects }, data) => {
  let options = {};
  if (data && data.options) options = data.options;
  const { carts } = await effects.gql.queries.carts(options);

  state.cart.totalRecords = carts ? carts.length : 0;
};

/*
 *
 */
export const getCarts = async ({ state, effects, actions }, data) => {
  console.log("action getCarts...");
  try {
    let options = {};
    if (_.isEmpty(data)) {
      options = {
        first: state.cart.cartPerPage,
        skip: (state.cart.activePage - 1) * state.cart.cartPerPage,
      };
    } else {
      if (data && data.all) options = {};
      else {
        options = data;
        if (!data.first) options.first = state.cart.cartPerPage;
        if (!data.skip)
          options.skip = (state.cart.activePage - 1) * state.cart.cartPerPage;
      }
    }
    //
    const { carts } = await effects.gql.queries.carts(options);
    _.each(carts, function (cart) {
      if (cart.event && cart.event.id) {
        // state.eventCart = cart
        state.currentCart = cart;
      } else {
        state.currentCart = cart;
        actions.cart.setCartDataByDate();
      }
    });
    //
    if (carts?.length > 0) {
      if (carts[0]?.items.length === 1) {
        state.currentSubTotalPrice = carts[0]?.items[0].priceEach;
      } else {
        state.currentSubTotalPrice = carts[0]?.items.reduce(
          (a, b) => parseFloat(a) + parseFloat(b.priceEach),
          0
        );
      }
    }

    if (data && data.getValues) return carts;
    else state.cart.carts = carts;
  } catch (e) {
    console.log(e, "getCarts errors");
  }
};

/*
 *
 */
export const saveCart = async ({ state, effects, actions }, data) => {
  try {
      const eventId = data.eventId;
      const cartId = data.cartId;

      data = {
        userId: state.currentUser.id,
        findMarketplace: false,
        ...data,
      };

      if (!eventId) delete data.eventId;
      if (cartId) data.cartId = cartId;

      console.log(data, "Pass data to saveCart");

      const { saveCart } = await effects.gql.mutations.saveCart(data);
      // data.addItems &&
      //   actions.alert.showSuccess({ message: "Item added to cart!" });
      state.currentCart = saveCart;
      actions.cart.setCartDataByDate();

      console.log(saveCart, "saveCart result");

      // if (eventId) {
      //   state.eventCart = {};
      //   await actions.event.getUserEvents({ userId: state.currentUser.id });
      // }

      // await actions.order.getOrders({where: {user: {id: state.currentUser.id}}});
      await actions.cart.getCarts({
        where: { user: { id: state.currentUser.id }, isPending: true },
      });

      return saveCart;
  } catch (e) {
    console.log(e.response.errors, "saveCart errors");
    const errors =
      e.response && e.response.errors && e.response.errors.length
        ? e.response.errors
        : "Error";
    if (errors && errors[0].message.indexOf("is not available") != -1) {
      const arr = errors[0].message.split(" | ");
      const message = arr[0];
      const itemId = arr[1] ? arr[1] : null;
      const deliveryComIndex = arr[2] ? arr[2] : null;
      if (itemId) return { error: true, message, itemId, deliveryComIndex };
      else
        await actions.alert.showError({
          message: e.response.errors[0]["message"],
          title: "Saving Cart",
        });
    } else
      await actions.alert.showError({
        message: e.response.errors[0]["message"],
        title: "Saving Cart",
      });
  }
};

/*
 *
 */
export const onChangePage = async ({ state }, page) => {
  state.cart.activePage = page;
};

/*
 *
 */
export const onCartAdded = ({ state }, data) => {
  state.currentCart.items.push({ ...data });
};

export const onCartRemoved = ({ state }, index) => {
  if (index > -1) state.currentCart.items.splice(index, 1);
};

/*
 *
 */
export const recalculateCart = async ({ effects }, data) => {
  return await effects.gql.mutations.recalculateCart(data);
};

/*
 *
 */
export const updateTip = async ({ effects }, data) => {
  return await effects.gql.mutations.updateTip(data);
};

/*
 *
 */
export const saveCartItem = async ({ effects }, data) => {
  return await effects.gql.mutations.saveCartItem(data);
};

/*
 *
 */
export const saveCartContacts = async ({ effects }, data) => {
  try {
    return await effects.gql.mutations.saveCartContacts(data);
  } catch (error) {
    console.log(error, "saveCartContacts error");
    return;
  }
};

/*
 *
 */
export const deleteCartItem = async ({ effects }, data) => {
  return await effects.gql.mutations.deleteCartItem(data);
};

/*
 *
 */
export const testSaveCart = async ({ effects }, data) => {
  return await effects.gql.mutations.testSaveCart(data);
};

/*
 *
 */
export const cart = async ({ effects }, data) => {
  return await effects.gql.queries.cart(data);
};

/*
 *
 */
export const notifyUsersOfAbandonedCarts = async ({ effects }, data) => {
  return await effects.gql.queries.notifyUsersOfAbandonedCarts(data);
};

export const emptyCart = async ({ state, effects, actions }, data) => {
  try {
    if (!data.eventId) delete data.eventId;
    data.userId = state.currentUser.id;
    console.log("Delete Cart", data);
    await effects.gql.mutations.saveCart(data);
    if (data.eventId) {
      await actions.event.getUserEvents({ userId: state.currentUser.id });
    }
    await actions.order.getOrders({
      where: { user: { id: state.currentUser.id } },
    });
    await actions.cart.getCarts({
      where: { user: { id: state.currentUser.id }, isPending: true },
    });
    if (data.eventId) {
      state.eventCart = {};
    } else {
      state.currentCart = {};
      state.cartDataByDate = [];
    }
  } catch (e) {
    console.log(e, "emptyCart errors");
  }
};

export const setCartDataByDate = ({ state }) => {
  const newData = [];
  let items = json(state.currentCart?.items);
  if (items?.length > 0) {
    items.map(
      (i) =>
        (i.formatDeliverBy = moment(i.deliverBy).format("dddd, MMMM D, YYYY"))
    );
    let data = groupBy(items, "formatDeliverBy");
    data = Object.values(data);
    data = data.sort((a, b) => (a[0]?.deliverBy > b[0]?.deliverBy ? 1 : -1));
    data.map((i) =>
      newData.push({
        data: i,
        date: i[0].deliverBy,
        deliverBy: moment(i[0]?.deliverBy, null).format("dddd, MMMM D, YYYY"),
        deliverTo: i[0].deliverTo,
        // isPickUp: i.filter(p => p.notes?.includes('Drive Up'))?.length > 0,
        // isBrunch: i.filter(p => p.notes?.includes('Brunch') || p.notes?.includes('After hour'))?.length > 0
      })
    );
    console.log("items" + newData);
  }
  state.cartDataByDate = newData;
};

export const addProductToCart = async ({ state, effects, actions }, item) => {
  try {
    let saveCartParams = null;
    console.log(state.currentCart, "state.currentCart");
    console.log(state.currentUser, "state.currentUser");
    const itemIndex = state.currentCart?.items?.findIndex(
      (i) => i?.product?.id === item?.id
    );
    console.log("deliverby", item?.deliverByDate);
    console.log("pickUpBy", item?.pickUpByDate);
    console.log("quantity", item.quantity);
    console.log("site", item.site);
    console.log(state.currentUser?.sites);
    if (item?.site?.place_id || item?.site?.googlePlacesId) {
      const params = {
        addressNumber: state.currentUser?.phones[0]?.number,
        addressName:
          state.currentUser?.firstName + " " + state.currentUser?.lastName,
        siteName: item.site?.name,
        employer: item.site,
        googlePlacesId: item.site?.place_id
          ? item.site?.place_id
          : item.site.googlePlacesId,
        address2: "",
        checkFullAddress: true,
      };
      await actions.user.saveUser(params);
    }
    console.log(state.currentUser?.sites);
    if (itemIndex > -1) {
      const updateItems = [];
      state.currentCart?.items?.map((d, i) =>
        updateItems.push(
          itemIndex === i
            ? {
                quantity: d?.quantity + parseInt(item.quantity),
                productId: d?.product?.id,
                originalProductId: d?.product?.id,
                deliverBy: item.deliverByDate,
                pickUpBy: item.pickUpByDate,
                deliverTo: {
                  id: state.currentUser?.sites?.[
                    state.currentUser?.sites?.length - 1
                  ]?.id,
                },
                id: d?.id,
              }
            : {
                quantity: d?.quantity,
                productId: d?.product?.id,
                originalProductId: d?.product?.id,
                deliverBy: d?.deliverBy,
                deliverTo: {
                  id: state.currentUser?.sites?.[
                    state.currentUser?.sites?.length - 1
                  ]?.id,
                },
                id: d?.id,
              }
        )
      );
      saveCartParams = {
        findMarketplace: false,
        updateItems,
        cartId: state.currentCart?.id,
      };
    } else if (state.currentCart?.id) {
      if (!state.currentCart?.items[0]?.deliverTo?.id) {
        actions.alert.showError({ message: "unable to add product to cart" });
        return;
      }
      saveCartParams = {
        findMarketplace: false,
        addItems: [
          {
            quantity: parseInt(item.quantity),
            productId: item?.id,
            originalProductId: item?.id,
            deliverBy: item.deliverByDate,
            pickUpBy: item.pickUpByDate,
            deliverTo: {
              id: state.currentUser?.sites?.[
                state.currentUser?.sites?.length - 1
              ]?.id,
            },
          },
        ],
        cartId: state.currentCart?.id,
      };
    } else {
      if (
        !state.currentUser?.sites?.[state.currentUser?.sites?.length - 1]?.id
      ) {
        console.log("not site");
        actions.alert.showError({ message: "unable to add product to cart" });
        return;
      }
      saveCartParams = {
        findMarketplace: false,
        addItems: [
          {
            quantity: parseInt(item.quantity),
            productId: item?.id,
            originalProductId: item?.id,
            deliverBy: item.deliverByDate,
            pickUpBy: item.pickUpByDate,
            deliverTo: {
              id: state.currentUser?.sites?.[
                state.currentUser?.sites?.length - 1
              ]?.id,
            },
          },
        ],
        
      };
    }
    console.log(
      "add cart---",
      saveCartParams,
      item.quantity,
      item.deliverByDate
    );
    await actions.cart.saveCart(saveCartParams);
    actions.user.setSelectedItem({});

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
