import gql from 'graphql-tag';
import { contractFragment } from './fragments';

export const saveContract = gql`
  mutation saveContract($data: ContractUpdateInput!, $where: ContractWhereUniqueInput) {
    saveContract(data: $data, where: $where) ${contractFragment}
  }
`;

export const deleteContract = gql`
  mutation deleteContract($where: ContractWhereUniqueInput) {
    deleteContract(where: $where) ${contractFragment}
  }
`;
