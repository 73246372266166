import gql from 'graphql-tag';
import { orderFragment } from './fragments';

/*
*
*/
export const saveOrder = gql`
  mutation saveOrder($data: OrderUpdateInput!, $where: OrderWhereUniqueInput) {
    saveOrder(data: $data, where: $where) ${orderFragment}
  }
`;

/*
*
*/
export const deleteOrder = gql`
  mutation deleteOrder($where: OrderWhereUniqueInput) {
    deleteOrder(where: $where) ${orderFragment}
  }
`;

/*
*
*/
export const finalizeOrder = gql`
  mutation finalizeOrder($targetDate: DateTime, $orderId: String) {
    finalizeOrder(targetDate: $targetDate, orderId: $orderId)
  }
`;

/*
*
*/
export const assignOrderToDriver = gql`
  mutation assignOrderToDriver($orderId: String!, $driverId: String!) {
    assignOrderToDriver(orderId: $orderId, driverId: $driverId) ${orderFragment}
  }
`;

/*
*
*/
export const cancelDelivery = gql`
  mutation cancelDelivery($orderId: String!, $driverId: String!) {
    cancelDelivery(orderId: $orderId, driverId: $driverId) ${orderFragment}
  }
`;

/*
*
*/
export const cancelDeliveryByOperator = gql`
  mutation cancelDeliveryByOperator($orderId: String!) {
    cancelDeliveryByOperator(orderId: $orderId) ${orderFragment}
  }
`;

/*
*
*/
export const markOrderPrepared = gql`
  mutation markOrderPrepared($orderId: String!) {
    markOrderPrepared(orderId: $orderId) ${orderFragment}
  }
`;

/*
*
*/
export const markOrderPickedUp = gql`
  mutation markOrderPickedUp($orderId: String!) {
    markOrderPickedUp(orderId: $orderId) ${orderFragment}
  }
`;

/*
*
*/
export const markOrderDelivered = gql`
  mutation markOrderDelivered($orderId: String!, $comment: String, $delivered: Boolean!) {
    markOrderDelivered(orderId: $orderId, comment: $comment, delivered: $delivered) ${orderFragment}
  }
`;

/*
*
*/
export const cancelOrder = gql`
  mutation cancelOrder($orderId: String!, $userId: String!, $eventId: String) {
    cancelOrder(orderId: $orderId, userId: $userId, eventId: $eventId)
  }
`;

/*
*
*/
export const createOrder = gql`
  mutation createOrder($cartId: String!, $eventId: String, $paymentMethodId: String, $useWallet: Boolean, $tipType: String, $tipAmount: Float, $notes: String, $sendUtensils: Boolean, $numberOfGiftedMeals: Int, $paymentPlan: [paymentPlanInput]) {
    createOrder(cartId: $cartId, eventId: $eventId, paymentMethodId: $paymentMethodId, useWallet: $useWallet, tipType: $tipType, tipAmount: $tipAmount, notes: $notes, sendUtensils: $sendUtensils, numberOfGiftedMeals: $numberOfGiftedMeals, paymentPlan: $paymentPlan) {
      id
    }
  }
`;

/*
*
*/
export const updateOrder = gql`
  mutation updateOrder($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!, $tipAmount: Float!, $tipType: String!) {
    updateOrder(data: $data, where: $where, tipAmount: $tipAmount, tipType: $tipType) ${orderFragment}
  }
`;

/*
*
*/
export const indexOrders = gql`
  mutation indexOrders($where: OrderWhereInput!, $clearIndexFirst: Boolean) {
    indexOrders(where: $where, clearIndexFirst: $clearIndexFirst)
  }
`;

/*
*
*/
export const testPromoOrder = gql`
  mutation testPromoOrder {
    testPromoOrder
  }
`;
