import gql from 'graphql-tag';

export const productFragment = gql`{
  id
  name
	nameLower
	description
	slug
	symbol
	posItemId
	groups {id name type}
	site {
		id
		name
		nameLower
		description
		slug
		rating
		address
		address2
		city
		state
		postalCode
		county
		country
		likeCount
	}
	categories
	likeCount
	images {
		id
		name
		url
		width
		height
		source
	}
	types
	rating
	sortOrder
	pricing {
		id
		type
		weekday
		startTime
		stopTime
		retailPrice
		salePrice
		wholesalePrice
		vendorPrice
		fee
		quantityLimit
		unitSize
	}
	showOnHHMenu
	isHidden
	isActive
	isAlcohol
	isFood
	isAddOn
	isTaxable
	isRefundable
	isEticket
	onSale
	isFree
}`
