import gql from "graphql-tag";

export const eventFragment = gql`
  {
    id
    name
    description
    isLocked
    isPrivate
    isCancelled
    isPostponed
    avatar
    shareCount
    likeCount
    maxBudgetPer
    duration
    isActive
	metadata
    creator {
      id firstName lastName avatar fullName email phones {id number}
    }
    subtype
    ticketMap
    gallery {
      id
      name
      source
      createdAt
    }
    producers {
      id
      name
    }
    products {
      id
      name
      description
      blurb
      slug
      symbol
      cloneOf {
        id
      }
      likeCount
      images {
        id
        name
        source
      }
      pricing {
        id
        type
        weekday
        startTime
        stopTime
        retailPrice
        salePrice
        wholesalePrice
        fee
        quantityLimit
        unitSize
      }
      eventDays {
        id
        name
        description
        startDate
        endDate
        site {
          id
          name
        }
      }
      posItemId
      medias {
        id
        name
        source
      }
    }
    tickets {
      id
      name
      description
      isActive
      sortOrder
			quantity
      productGroup {
        id
        name
        description
      }
      features
      tags {
        id
        name
      }
      subproducts {
        id
        name
        description
        blurb
        slug
        symbol
        cloneOf {
          id
        }
        likeCount
        images {
          id
          name
          source
        }
        pricing {
          id
          type
          weekday
          startTime
          stopTime
          retailPrice
          salePrice
          wholesalePrice
          fee
          quantityLimit
          unitSize
        }
        eventDays {
          id
          name
          description
          startDate
          endDate
          site {
            id
            name
          }
        }
      }
      eventDays {
        id
        name
        description
        startDate
        endDate
        performers {
          id
          performer {
            id
            name
            avatar
          }
        }
      }
      blurb
      slug
      symbol
      posItemId
      site {
        id
        name
        address
        country
        postalCode
      }
      cloneOf {
        id
      }
      categories
      likeCount
      pricing {
        id
        type
        weekday
        startTime
        stopTime
        retailPrice
        salePrice
        wholesalePrice
        fee
        quantityLimit
        unitSize
        saleEnds
      }
    }
    days {
      id
      name
      isPrivate
      description
      startDate
      endDate
      site {
        id
        name
        address
        address2
        city
        state
        postalCode
        country
        phones {
          id
          number
        }
      }
    }
    eventbriteUrl
	createdAt
  }
`;
