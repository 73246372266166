import { isEmpty, keyBy, map } from 'lodash';

/*
*
*/
export const getTotalCalendars = async ({ state, effects }) => {
  const { calendars } = await effects.gql.queries.calendars()

  state.calendar.totalRecords = calendars ? calendars.length : 0
}

/*
*
*/
export const getCalendars = async ({ state, effects }, data) => {
  console.log('getCalendars initial data', data);

  try {
    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.calendar.calendarPerPage,
        skip: (state.order.activePage - 1) * state.calendar.calendarPerPage
      }
    } else {
      options = data;
      if (!data.first) options.first = state.calendar.calendarPerPage;
      if (!data.skip) options.skip = (state.calendar.activePage - 1) * state.calendar.calendarPerPage;
    }

    console.log(options, 'getCalendars options');

    const { calendars } = await effects.gql.queries.calendars(options);

    console.log(calendars, 'getCalendars results');
    if (data.getValues) {
      return calendars;
    } else {
      state.calendar.calendars = calendars;
    }
  } catch (e) {
    console.log(e, 'getCalendars errors');
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
}

/*
*
*/
export const saveCalendar = async ({ state, actions, effects }, data) => {
  try {
    return await effects.gql.mutations.saveCalendar(data)
  } catch (e) {
    console.log(e, 'saveCalendar errors')
    actions.alert.showError({ message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error', title: 'Saving Calendar' });
  }
}

/*
*
*/
export const onChangePage = async ({ state }, page) => {
  state.calendar.activePage = page
}

/*
*
*/
export const onCalendarAdded = ({ state }, data) => {
  state.calendar.calendars.push(data)
}
