import { _ } from 'vue-underscore'
/*
*
*/
export const getTotalEvents = async ({ state, effects }, data) => {
  let options = {}
  if (data && data.options) options = data.options
  const { events } = await effects.gql.queries.events(options)

  state.event.totalRecords = events ? events.length : 0
}

/*
*
*/
export const getEvents = async ({ state, effects }, data) => {
  console.log('action getEvents...', data)
  try {
    let options = {}
    if (_.isEmpty(data)) {
      options = {
        first: state.event.eventPerPage,
        skip: (state.event.activePage - 1) * state.event.eventPerPage
      }
    } else {
      if (data && data.all) options = {}
      else {
        options = data;
        if (!data.first) options.first = state.event.eventPerPage;
        if (!data.skip) options.skip = (state.event.activePage - 1) * state.event.eventPerPage;
      }
    }
    //
    const { events } = await effects.gql.queries.events(options);
    console.log(events, '======')
    state.event.events = events;
    return events
  } catch (e) {
    console.log(e, 'getEvents errors');
  }
}

export const getProducts = async ({ effects }, data) => {
  console.log('action getProducts...', data)
  try {
    const { products } = await effects.gql.queries.products(data);
    console.log(products, 'products')
    return products
  } catch (e) {
    console.log(e, 'getProducts errors');
  }
}

/*
*
*/
export const saveEvent = async ({ state, actions, effects }, data) => {
  try {
    return await effects.gql.mutations.saveEvent(data);
  } catch (exception) {
    console.log('saveEvent error', exception);
    actions.alert.showError({ message: exception.response.errors[0]['message'], title: 'Creating Event' })
  }
}

/*
*
*/
export const createEventNotifyAttendees = async ({ state, actions, effects }, data) => {
  console.log('action createEventNotifyAttendees...', data);

  try {
    const { createEventNotifyAttendees } = await effects.gql.queries.createEventNotifyAttendees(data);

    console.log(createEventNotifyAttendees, 'createEventNotifyAttendees results');

    await actions.event.getUserEvents({userId: state.currentUser.id });
  } catch (e) {
    console.log(e, 'saveEvent errors')
    actions.alert.showError({ message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error', title: 'Saving Event' });
  }
}

/*
*
*/
export const onChangePage = async ({ state }, page) => {
  state.event.activePage = page
}

/*
*
*/
export const onClearEvent = ({ state }) => {
  state.event.events = []
}

/*
*
*/
export const checkEventSubscription = async ({ effects, actions }, data) => {
  if (data && data.eventId) {
    effects.gql.subscriptions.eventSubscription(actions.onEventUpdate)
  }
}

/*
*
*/
export const onEventUpdate = ({ state }, data) => {
  console.log("Subscrption")
  if (data && data.id) state.event.events[data.id] = data
}

export const saveEventVendorApplication = async ({effects}, data) => {
  console.log(data, 'saveEventVendorApplication params');
  try {
    const {saveEventVendorApplication} = await effects.gql.mutations.saveEventVendorApplication(data);
    return saveEventVendorApplication;
  } catch(e){
    console.log(e, 'saveEventVendorApplication issue');
  }
}
