import { _ } from 'vue-underscore'
  /*
  *
  */
  export const getTotalOrders = async ({ state, effects }, data) => {
    let options = {}
    if (data && data.options) options = data.options
    const { orders } = await effects.gql.queries.orders(options)

    state.order.totalRecords = orders ? orders.length : 0
  }

  /*
  *
  */
  export const getOrders = async ({ state, effects }, data) => {
    console.log('action getOrders...')
    try {
      let options = {}
      if(_.isEmpty(data)) {
        options = {
          first: state.order.orderPerPage,
          skip: (state.order.activePage - 1) * state.order.orderPerPage
        }
      } else {
        if (data && data.all) options = {}
        else {
          options = data;
          if(!data.first) options.first = state.order.orderPerPage;
          if(!data.skip) options.skip = (state.order.activePage - 1) * state.order.orderPerPage;
        }
      }
      //
      const { orders } = await effects.gql.queries.orders(options)
      if (data && data.getValues) return orders
      else state.order.orders = orders
    } catch (e) {
      console.log(e, 'getOrders errors');
    }
  }

  /*
  *
  */
  export const saveOrder = async ({ effects, actions }, data) => {
    try {
      return await effects.gql.mutations.saveOrder(data)
    } catch (e) {
      console.log(e, 'saveOrder errors')
      actions.alert.showError({ message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error', title: 'Saving Order' });
    }
  }

  /*
  *
  */
  export const onChangePage = async ({ state }, page) => {
    state.order.activePage = page
  }

  /*
  *
  */
  export const onOrderAdded = ({ state }, data) => {
    state.order.push(data)
  }

/*
*
*/
export const finalizeOrder = async ({ effects }, data) => {
  return await effects.gql.mutations.finalizeOrder(data)
}

/*
*
*/
export const deleteOrder = async ({ effects }, data) => {
  return await effects.gql.mutations.deleteOrder(data)
}

/*
*
*/
export const assignOrderToDriver = async ({ effects }, data) => {
  return await effects.gql.mutations.assignOrderToDriver(data)
}

/*
*
*/
export const cancelDelivery = async ({ effects }, data) => {
  return await effects.gql.mutations.cancelDelivery(data)
}

/*
*
*/
export const cancelDeliveryByOperator = async ({ effects }, data) => {
  return await effects.gql.mutations.cancelDeliveryByOperator(data)
}

/*
*
*/
export const markOrderPrepared = async ({ effects }, data) => {
  return await effects.gql.mutations.markOrderPrepared(data)
}

/*
*
*/
export const markOrderPickedUp = async ({ effects }, data) => {
  return await effects.gql.mutations.markOrderPickedUp(data)
}

/*
*
*/
export const markOrderDelivered = async ({ effects }, data) => {
  return await effects.gql.mutations.markOrderDelivered(data)
}

/*
*
*/
export const cancelOrder = async ({ effects }, data) => {
  return await effects.gql.mutations.cancelOrder(data)
}

/*
*
*/
export const createOrder = async ({ state, actions, effects }, data) => {
  console.log(data, 'createOrder data...')
  try {
    const { createOrder } = await effects.gql.mutations.createOrder(data)
    console.log(createOrder, 'createOrder result')
    if (data.eventId) {
      state.eventCart = {}
    } else {
      state.currentCart = {}
    }
    // await actions.event.getUserEvents({userId: state.currentUser.id })
    await actions.order.getOrders({where: { user: { id: state.currentUser.id }}})
    return createOrder
  } catch (e) {
    console.log(e, 'createOrder errors')
    await actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Saving Order' })
  }
}

/*
*
*/
export const updateOrder = async ({ effects }, data) => {
  return await effects.gql.mutations.updateOrder(data)
}

/*
*
*/
export const indexOrders = async ({ effects }, data) => {
  return await effects.gql.mutations.indexOrders(data)
}

/*
*
*/
export const testPromoOrder = async ({ effects }, data) => {
  return await effects.gql.mutations.testPromoOrder(data)
}

/*
*
*/
export const getOrdersForDelivery = async ({ effects }, data) => {
  return await effects.gql.queries.getOrdersForDelivery(data)
}

/*
*
*/
export const getDriverOrdersForDelivery = async ({ effects }, data) => {
  return await effects.gql.queries.getDriverOrdersForDelivery(data)
}

/*
*
*/
export const order = async ({ effects }, data) => {
  return await effects.gql.queries.order(data)
}

/*
*
*/
export const getOrdersByTask = async ({ effects }, data) => {
  return await effects.gql.queries.getOrdersByTask(data)
}

/*
*
*/
export const getOrdersByFood = async ({ effects }, data) => {
  return await effects.gql.queries.getOrdersByFood(data)
}

/*
*
*/
export const getOrdersBySchool = async ({ effects }, data) => {
  return await effects.gql.queries.getOrdersBySchool(data)
}

/*
*
*/
export const getOrdersByCustomer = async ({ effects }, data) => {
  return await effects.gql.queries.getOrdersByCustomer(data)
}

/*
*
*/
export const getInviteeOrders = async ({ effects }, data) => {
  return await effects.gql.queries.getInviteeOrders(data)
}

/*
*
*/
export const getUsersWithAnOrder = async ({ effects }, data) => {
  return await effects.gql.queries.getUsersWithAnOrder(data)
}

/*
*
*/
export const notifyUsersWithOrdersForDeliveryToday = async ({ effects }, data) => {
  return await effects.gql.queries.notifyUsersWithOrdersForDeliveryToday(data)
}

/*
*
*/
export const notifyUsersToOrderBySix = async ({ effects }, data) => {
  return await effects.gql.queries.notifyUsersToOrderBySix(data)
}
