import {Settings} from '../../settings';
import * as internalActions from './internalActions';
import store from 'store/dist/store.modern';
import {_} from 'vue-underscore';
import {json} from "overmind";

// const TOKEN_NAME = Settings.appName.toLowerCase() + '-token';
const TOKEN_NAME = '@' + Settings.appName.toLowerCase().replace(/s/g, '') + ':token';

export const internal = internalActions;

/*
*
*/
export const isProfileUnset = async ({state}) => {
  return _.isEmpty(state.currentUser.email) ||
    _.isEmpty(state.currentUser.fullName) ||
    _.isEmpty(state.currentUser.id);
};

/*
*
*/
export const createAppUser = async ({state, effects, actions}, variables) => {
  console.log('creating app user...', variables);

  try {
    const user = await effects.gql.mutations.createAppUser(variables);
    console.log(user.createAppUser.user, 'createAppUser');
    state.currentUser = user.createAppUser.user;
    return user.createAppUser;
  } catch (e) {
    console.log(e, 'createAppUser errors')
    actions.alert.showError({ message: e.response.errors[0]["message"] });
  }
};

/*
*
*/
export const verifySmsCode = async ({effects}, variables) => {
  console.log('verifying sms code...', variables);
  const result = await effects.gql.mutations.verifySmsCode(variables);
  return result.verifySmsCode;
};

/*
*
*/
export const setStoredAuthToken = async ({state}) => {
  return store.set(TOKEN_NAME, {token: state.authToken, userId: state.currentUser.id});
};

/*
*
*/
export const getStoredAuthToken = async () => {
  if (!store.get(TOKEN_NAME)) return store.set(TOKEN_NAME, {token: null, userId: null});
  return store.get(TOKEN_NAME);
};

/*
*
*/
export const removeStoredAuthToken = async () => {
  return store.remove(TOKEN_NAME);
};

/*
*
*/
export const logout = async ({state, actions}) => {
  console.log('Logout');
  state.currentUser = null;
  state.isLoggedIn = false;
  state.authToken = null;
  state.currentCart = null;
  await localStorage.removeItem('chewbox_currentUser');
  await localStorage.removeItem('chewbox_currentCart');
  await localStorage.removeItem('chewbox_order');
  await localStorage.removeItem('chewbox_user');
  await actions.removeStoredAuthToken();
  return true;
};

/*
*
*/
export const login = async ({effects, state, actions}, variables) => {
  try {
    const {login} = await effects.gql.mutations.login(variables);

    state.currentUser = login.user;
    state.isLoggedIn = true;
    state.authToken = login.token;

    await actions.setStoredAuthToken();
    return true;

  } catch (e) {
    await actions.removeStoredAuthToken();
    state.errors = e.response.errors;
  }
}

/*
*
*/
export const loginWithToken = async ({effects, state, actions}, variables) => {
  console.log('logging in with token...', variables);

  if (!variables.token || !variables.userId) {
    state.isAuthenticating = false;
    if (actions) {
      await actions?.logout();
    }
    return;
  }

  try {
    const {loginWithToken} = await effects.gql.mutations.loginWithToken(variables);
    state.currentUser = loginWithToken.user;
    state.currentUser.paymentMethods = (loginWithToken.user?.paymentMethods || []).filter(p => p.type === 'payment' && p.isValid);
    state.isLoggedIn = true;
    state.authToken = variables.token;
    state.isAuthenticating = false;
    await actions.setStoredAuthToken();
    return true;

  } catch (e) {
    console.log('e', e.message, Object.keys(e));
    if (e.message?.includes('Could not verify you') ||e.message?.includes('No user found with user id') ) {
      state.isAuthenticating = false;
      await actions?.removeStoredAuthToken();
      await actions?.logout();
      if (state.route) {

        json(state.route).push('/');
      }
    }

    return false;
  }
};

export const startLoading = ({state}) => {
  state.isLoading = true;
};

export const finishLoading = ({state}) => {
  state.isLoading = false;
};

export const setFullEditor = ({state}) => {
  state.isFullEditor = !state.isFullEditor
}

export const selectPreviewImage = ({state}, image) => {
  state.selectedImage = image
}